var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ingredient-list my-3" },
    [
      _c("h4", [_vm._v(_vm._s(_vm.$t("recipes.ingredients.title")))]),
      _vm._l(_vm.ingredients, function(ingredient) {
        return _c(
          "div",
          {
            key: ingredient.ingredient_id,
            staticClass: "d-flex align-center pb-1"
          },
          [
            _c("span", [
              _vm._v(
                " - " +
                  _vm._s(ingredient.amount) +
                  " " +
                  _vm._s(ingredient.unit) +
                  " " +
                  _vm._s(ingredient.ingredient)
              )
            ])
          ]
        )
      }),
      _c(
        "v-btn",
        {
          staticClass: "mt-3",
          attrs: {
            rounded: "",
            large: "",
            block: "",
            depressed: "",
            color: "primary",
            disabled: _vm.disableAddBtn,
            "data-html2canvas-ignore": "true"
          },
          on: {
            click: function($event) {
              return _vm.addProducts()
            }
          }
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v("$listaSpesa")]),
          _vm._v(_vm._s(_vm.$t("recipes.ingredients.addToList")))
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }