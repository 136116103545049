var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _vm.article
        ? _c("ReceiptDetail", { attrs: { article: _vm.article } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }