<template>
  <div id="pdf-content" class="recipe-detail">
    <v-img
      v-if="image"
      width="100%"
      :src="image"
      :alt="imageAlt"
      class="recipe-image"
    />
    <v-img
      v-show="false"
      v-if="image"
      width="100%"
      :src="image"
      :alt="imageAlt"
      class="recipe-image"
    />
    <div class="d-flex justify-space-between align-center px-2">
      <div
        class="article-type text-uppercase text-body-0 font-weight-semibold"
        :class="courseClass?.toLowerCase()"
      >
        {{ course }}
      </div>
      <div data-html2canvas-ignore="true">
        <v-btn
          v-if="isAuthenticated"
          icon
          color="secondary"
          class="icon-wraper"
          @click="toggleFav(article.articleId)"
        >
          <v-icon color="secondary">{{
            articleStatus(article.articleId) ? "$heartfull" : "$heart"
          }}</v-icon>
        </v-btn>
        <v-btn icon color="default" class="icon-wraper">
          <v-icon color="default" @click="share">$share</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="pa-2">
      <h4>{{ article.title }}</h4>

      <div v-html="$ebsn.meta(article, 'articletype.recipe_description')"></div>

      <div class="quick-info" v-if="article.articleType.name == 'ricette'">
        <div class="section d-flex">
          <div class="mr-2"><v-icon color="secondary">$difficulty</v-icon></div>
          <div class="flex-grow-1 text-body-0">
            <div class="font-weight-semibold">
              {{ $t("recipes.difficulty") }}
            </div>
            <div>{{ $ebsn.meta(article, "articletype.difficulty") }}</div>
          </div>
        </div>
        <div class="section d-flex">
          <div class="mr-2"><v-icon color="secondary">$prepTime</v-icon></div>
          <div class="flex-grow-1  text-body-0">
            <div class="font-weight-semibold">
              {{ $t("recipes.prepTime") }}
            </div>
            <div>
              {{ $ebsn.meta(article, "articletype.recipe_prep_time") }}
              {{ $ebsn.meta(article, "articletype.recipe_prep_time_text") }}
            </div>
          </div>
        </div>
        <div class="section d-flex">
          <div class="mr-2"><v-icon color="secondary">$servings</v-icon></div>
          <div class="flex-grow-1 text-body-0">
            <div class="font-weight-semibold">
              {{ $t("recipes.servings") }}
            </div>
            <div>
              {{ $ebsn.meta(article, "articletype.recipe_servings") }}
              {{ $ebsn.meta(article, "articletype.recipe_servings_type") }}
            </div>
          </div>
        </div>
        <div class="section d-flex">
          <div class="mr-2">
            <v-icon color="secondary">$cookingTime</v-icon>
          </div>
          <div class="flex-grow-1  text-body-0">
            <div class="font-weight-semibold">
              {{ $t("recipes.cookingTime") }}
            </div>
            <div>
              {{ $ebsn.meta(article, "articletype.recipe_cook_time") }}
              {{ $ebsn.meta(article, "articletype.recipe_cook_time_text") }}
            </div>
          </div>
        </div>
      </div>

      <IngredientList
        v-if="article.articleType.name == 'ricette'"
        :ingredients="$ebsn.meta(article, 'articletype.recipe_ingredients')"
        :disableAddBtn="!isAuthenticated"
      />

      <div
        v-html="article.content"
        @click="clicked"
        class="article-content"
      ></div>
    </div>
  </div>
</template>
<style lang="scss">
.recipe-detail {
  .recipe-image {
    margin-bottom: 12px;
    border-radius: 0 0px 25px 25px;
  }
  .quick-info {
    background-color: var(--v-grey-lighten1);
    padding: 18px 10px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
  }
  .article-content {
    ul {
      padding-left: 30px !important;
      margin-bottom: 12px;
      li {
        font-size: 16px;
      }
    }
  }
}
.only-on-pdf {
  font-family: "Poppins", arial, sans-serif;
  .article-content * {
    page-break-inside: avoid;
    page-break-before: auto;
    page-break-after: auto;
  }
  .ingredient-list {
    div {
      page-break-inside: avoid;
      page-break-before: auto;
      page-break-after: auto;
    }
  }
  a {
    color: inherit;
    text-decoration: none !important;
  }
}
</style>
<script>
import IngredientList from "@/components/article/IngredientList.vue";
import clickHandler from "~/mixins/clickHandler";
import { mapGetters, mapActions } from "vuex";
import socialsharing from "@/mixins/socialsharing";
import html2pdf from "html2pdf.js";
import has from "lodash/has";

export default {
  mixins: [clickHandler, socialsharing],
  components: {
    IngredientList
  },
  props: {
    article: { type: Object, required: true }
  },
  data() {
    return {
      savedArticles: []
    };
  },
  computed: {
    ...mapGetters({
      getSavedArticles: "custom/getSavedArticles",
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    }),
    templateName() {
      return "Static";
      // if (this.page.template && this.page.template == "page-leftsidebar.php") {
      //   return "PageLeftsidebar";
      // } else {
      //   return "Static";
      // }
    },

    image() {
      return has(this.article, "media[0].url")
        ? this.article.media[0].url
        : this.article.refImageUrl
        ? this.article.refImageUrl
        : "";
    },
    imageAlt() {
      return this.article.title || this.article.title;
    },
    course() {
      return this.article?.articleClass?.find(
        ac => ac.articleClassGroup.name == this.article.articleType.name
      )?.description;
    },
    courseClass() {
      return this.article?.articleClass?.find(
        ac => ac.articleClassGroup.name == this.article.articleType.name
      )?.name;
    }
  },
  methods: {
    ...mapActions({
      toggleArticle: "custom/toggleArticle"
    }),
    toggleFav(id) {
      if (this.isAuthenticated) {
        this.toggleArticle(id);
      }
    },
    articleStatus(articleId) {
      return this.savedArticles.some(article => article === articleId);
    },
    // share() {
    //   const element = document.getElementById("pdf-content");
    //   const title = this.article.title;
    //   element.classList.add("only-on-pdf");
    //   const options = {
    //     margin: 10,
    //     filename: title,
    //     html2canvas: { scale: 2 },
    //     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    //     image: { type: "jpeg", quality: 0.1 },
    //     pagebreak: {
    //       avoid: [
    //         "h2",
    //         "h3",
    //         "h4",
    //         "p",
    //         ".article-content div:not(:last-child)",
    //         "ingredient-list"
    //       ]
    //     }
    //   };
    //   html2pdf()
    //     .from(element)
    //     .set(options)
    //     .save(title)
    //     .then(pdfSrc => {
    //       element.classList.remove("only-on-pdf");
    //       console.log(pdfSrc);
    //     });
    // }
    async share() {
      const element = document.getElementById("pdf-content");
      const title = this.article.title;
      const opt = {
        margin: 8,
        filename: title,
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        image: { type: "jpeg", quality: 0.9 },
        useCORS: true
      };
      element.classList.add("only-on-pdf");
      //let pdf;
      html2pdf()
        .from(element)
        .set(opt)
        .outputPdf()
        .then(pdf => {
          element.classList.remove("only-on-pdf");
          const pdfBase64 = btoa(pdf);
          const dataUrl = `data:application/pdf;base64,${pdfBase64}`;
          // console.log("pdfBase64", dataUrl);
          window.plugins.socialsharing.share(null, title, dataUrl, null);
        });
    }
  },
  mounted() {
    if (this.user && this.user.userId) {
      this.savedArticles = this.getSavedArticles(this.user.userId);
    } else {
      this.savedArticles = [];
    }
  }
};
</script>
