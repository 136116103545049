var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recipe-detail", attrs: { id: "pdf-content" } },
    [
      _vm.image
        ? _c("v-img", {
            staticClass: "recipe-image",
            attrs: { width: "100%", src: _vm.image, alt: _vm.imageAlt }
          })
        : _vm._e(),
      _vm.image
        ? _c("v-img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ],
            staticClass: "recipe-image",
            attrs: { width: "100%", src: _vm.image, alt: _vm.imageAlt }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center px-2" },
        [
          _c(
            "div",
            {
              staticClass:
                "article-type text-uppercase text-body-0 font-weight-semibold",
              class: _vm.courseClass?.toLowerCase()
            },
            [_vm._v(" " + _vm._s(_vm.course) + " ")]
          ),
          _c(
            "div",
            { attrs: { "data-html2canvas-ignore": "true" } },
            [
              _vm.isAuthenticated
                ? _c(
                    "v-btn",
                    {
                      staticClass: "icon-wraper",
                      attrs: { icon: "", color: "secondary" },
                      on: {
                        click: function($event) {
                          return _vm.toggleFav(_vm.article.articleId)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { color: "secondary" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.articleStatus(_vm.article.articleId)
                              ? "$heartfull"
                              : "$heart"
                          )
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "icon-wraper",
                  attrs: { icon: "", color: "default" }
                },
                [
                  _c(
                    "v-icon",
                    { attrs: { color: "default" }, on: { click: _vm.share } },
                    [_vm._v("$share")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "pa-2" },
        [
          _c("h4", [_vm._v(_vm._s(_vm.article.title))]),
          _c("div", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$ebsn.meta(_vm.article, "articletype.recipe_description")
              )
            }
          }),
          _vm.article.articleType.name == "ricette"
            ? _c("div", { staticClass: "quick-info" }, [
                _c("div", { staticClass: "section d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "mr-2" },
                    [
                      _c("v-icon", { attrs: { color: "secondary" } }, [
                        _vm._v("$difficulty")
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex-grow-1 text-body-0" }, [
                    _c("div", { staticClass: "font-weight-semibold" }, [
                      _vm._v(" " + _vm._s(_vm.$t("recipes.difficulty")) + " ")
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.$ebsn.meta(_vm.article, "articletype.difficulty")
                        )
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "section d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "mr-2" },
                    [
                      _c("v-icon", { attrs: { color: "secondary" } }, [
                        _vm._v("$prepTime")
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex-grow-1 text-body-0" }, [
                    _c("div", { staticClass: "font-weight-semibold" }, [
                      _vm._v(" " + _vm._s(_vm.$t("recipes.prepTime")) + " ")
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$ebsn.meta(
                              _vm.article,
                              "articletype.recipe_prep_time"
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.$ebsn.meta(
                              _vm.article,
                              "articletype.recipe_prep_time_text"
                            )
                          ) +
                          " "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "section d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "mr-2" },
                    [
                      _c("v-icon", { attrs: { color: "secondary" } }, [
                        _vm._v("$servings")
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex-grow-1 text-body-0" }, [
                    _c("div", { staticClass: "font-weight-semibold" }, [
                      _vm._v(" " + _vm._s(_vm.$t("recipes.servings")) + " ")
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$ebsn.meta(
                              _vm.article,
                              "articletype.recipe_servings"
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.$ebsn.meta(
                              _vm.article,
                              "articletype.recipe_servings_type"
                            )
                          ) +
                          " "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "section d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "mr-2" },
                    [
                      _c("v-icon", { attrs: { color: "secondary" } }, [
                        _vm._v("$cookingTime")
                      ])
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex-grow-1 text-body-0" }, [
                    _c("div", { staticClass: "font-weight-semibold" }, [
                      _vm._v(" " + _vm._s(_vm.$t("recipes.cookingTime")) + " ")
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$ebsn.meta(
                              _vm.article,
                              "articletype.recipe_cook_time"
                            )
                          ) +
                          " " +
                          _vm._s(
                            _vm.$ebsn.meta(
                              _vm.article,
                              "articletype.recipe_cook_time_text"
                            )
                          ) +
                          " "
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm.article.articleType.name == "ricette"
            ? _c("IngredientList", {
                attrs: {
                  ingredients: _vm.$ebsn.meta(
                    _vm.article,
                    "articletype.recipe_ingredients"
                  ),
                  disableAddBtn: !_vm.isAuthenticated
                }
              })
            : _vm._e(),
          _c("div", {
            staticClass: "article-content",
            domProps: { innerHTML: _vm._s(_vm.article.content) },
            on: { click: _vm.clicked }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }